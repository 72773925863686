import React, {useState} from 'react';
import {faPlus} from "@fortawesome/free-solid-svg-icons";

import './AddTask.css'
import {Button} from "../button/Button";
import {generateId} from "../../utils/Utils";

export const AddTask = ({addTask}) => {
    const [value, setValue] = useState('');
    const handleSubmit = e => {
        e.preventDefault();

        if(!value) {
            return;
        }

        handleAddTask(value);
        setValue('');
    }

    const handleAddTask = (task) => {
        const newTask = {
            id: generateId(),
            task,
            completed: false,
            edit: false,
        };

        addTask(newTask);
    };

    return (
        <form onSubmit={handleSubmit} className={'todo-add-step-container'}>
            <input placeholder='Add something...'
                   type='text'
                   value={value}
                   onChange={(e) => setValue(e.target.value)}
            />
            <Button
                onClick={handleSubmit}
                status={'primary'}
                icon={faPlus}
            />
        </form>)
}
