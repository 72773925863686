import React, { useState } from "react";
import { Dashboard } from "./components/dashboard/Dashboard";
import { NavigationMenu } from "./components/navigation-menu/NavigationMenu";
import {generateId} from "./utils/Utils";
import {Routes, Route} from "react-router-dom";
import SharedCollectionView from "./pages/shared/SharedCollectionView";

function App() {
    // Load lists from localStorage or initialize with an empty array
    const [lists, setLists] = useState(() => {
        const savedLists = localStorage.getItem("lists");
        return savedLists ? JSON.parse(savedLists) : [];
    });

    // Load selectedListId from localStorage or initialize with null
    const [selectedListId, setSelectedListId] = useState(() => {
        const savedSelectedListId = localStorage.getItem("selectedListId");
        return savedSelectedListId ? JSON.parse(savedSelectedListId) : null;
    });

    const handleAddList = (listName) => {
        if (!listName.trim()) return;
        const newList = { id: generateId(), name: listName, todos: [] };
        setLists((prevLists) => [...prevLists, newList]);
        setSelectedListId(newList.id);
    };

    const handleDeleteList = (listId) => {
        setLists(lists.filter((list) => list.id !== listId));
        if (selectedListId === listId) setSelectedListId(null);
    };

    const handleSelectList = (listId) => {
        setSelectedListId(listId);
    };

    const handleAddSharedCollection = (collection) => {
        const sharedName = `${collection.name} (shared)`
        const newList = {id: generateId(), name: sharedName, todos: collection.todos};
        setLists(prevLists => [...prevLists, newList]);
        setSelectedListId(newList.id);
    }

    return (
        <>
            <NavigationMenu
                lists={lists}
                setLists={setLists}
                selectedListId={selectedListId}
                onAddList={handleAddList}
                onSelectList={handleSelectList}
            />
            <Routes>
                {/* Default route for your current layout */}
                <Route
                    path="/"
                    element={
                        <Dashboard
                            lists={lists}
                            selectedListId={selectedListId}
                            setLists={setLists}
                            onDeleteList={handleDeleteList}
                        />
                    }
                />

                {/* Route for shared collection */}
                <Route
                    path="/share"
                    element={
                        <SharedCollectionView
                            setSharedCollection={handleAddSharedCollection}
                        />
                    }
                />
            </Routes>
        </>
    );
}

export default App;
