import React from "react";
import { ReactSortable } from "react-sortablejs";

import "./TaskList.css";
import { Task } from "./task/Task";
import { EditTask } from "./edit-task/EditTask";

export const TaskList = ({
    todos,
    setTodos,
    deleteTodo,
    editTodo,
    updateTodo,
    saveTodo,
}) => {
    const toggleComplete = (id) => {
        setTodos(
            todos.map((todo) =>
                todo.id === id ? { ...todo, completed: !todo.completed } : todo,
            ),
        );
    };

    return (
        <ReactSortable
            list={todos}
            setList={setTodos}
            className="steps-container"
            handle=".drag-handle" // This ensures sorting only starts when the handle is clicked
        >
            {todos.map((todo) => {
                return todo.edit ? (
                    <EditTask saveTodo={saveTodo} task={todo} key={todo.id} />
                ) : (
                    <Task
                        todo={todo}
                        toggleComplete={toggleComplete}
                        deleteTodo={deleteTodo}
                        editTodo={editTodo}
                        updateTodo={updateTodo}
                        subtasks={todo.subtasks || []}
                        key={todo.id}
                    />
                );
            })}
        </ReactSortable>
    );
};
