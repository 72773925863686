import React, { useState } from "react";
import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";

import { Button } from "../../../button/Button";
import "./Subtask.css";
import ActionMenu from "../../../action-menu/ActionMenu";

export const Subtask = ({
    subtask,
    toggleComplete,
    deleteSubtask,
    saveSubtask,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedTask, setEditedTask] = useState(subtask.task);
    const actions = [
        {
            label: "Edit",
            icon: faPencil,
            callback: () => handleEdit(),
        },
        {
            label: "Delete",
            icon: faTrash,
            callback: () => deleteSubtask(subtask.id),
        },
    ];

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!editedTask) return;

        const editedSubtask = { ...subtask, task: editedTask };
        saveSubtask(editedSubtask);
        setIsEditing(false);
    };

    const handleEdit = () => {
        setEditedTask(subtask.task);
        setIsEditing(true);
    };

    const handleComplete = () => {
        const updatedSubtask = { ...subtask, completed: !subtask.completed };
        toggleComplete(updatedSubtask);
    };

    return (
        <li>
            {isEditing ? (
                <form className="subtask-container" onSubmit={handleSubmit}>
                    <input
                        type="text"
                        value={editedTask}
                        onChange={(event) => setEditedTask(event.target.value)}
                        placeholder="Edit subtask"
                    />
                    <Button onClick={handleSubmit} type="primary" text="Save" />
                </form>
            ) : (
                <>
                    <div className={"subtask-container"}>
                        <div
                            className={`${subtask.completed && "completed"} subtask-text`}
                            onClick={handleComplete}
                        >
                            {subtask.task}
                        </div>
                        <div className={"subtask-buttons-container"}>
                            <ActionMenu actions={actions}></ActionMenu>
                        </div>
                    </div>
                </>
            )}
        </li>
    );
};
