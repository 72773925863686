export const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const generateId = () => {
    return Date.now();
}

export const filterSubtasks = (subtasks, filter) => {
    return subtasks.filter((subtask) => subtask.task.toLowerCase().includes(filter.toLowerCase()));
}

export const filterTodos = (todos, filter) => {
    return todos.filter((todo) => todo.task.toLowerCase().includes(filter.toLowerCase()));
}


