import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faXmark} from "@fortawesome/free-solid-svg-icons";

import "./ListMenu.css";

export const ListMenu = ({lists, setShowListMenu, selectedListId, onAddList, onSelectList}) => {
    const [newListName, setNewListName] = useState('');

    const handleAddList = (e) => {
        e.preventDefault();
        if (newListName.trim()) {
            onAddList(newListName.trim());
            setNewListName('');
            setShowListMenu(false);
        }
    };

    const handleSelectList = (listId) => {
      onSelectList(listId);
      setShowListMenu(false);
    }

    return (
        <div className="list-menu">
            <div className="close-menu-icon" onClick={() => setShowListMenu(false)}>
                <FontAwesomeIcon icon={faXmark}/>
            </div>
            <form onSubmit={handleAddList} className="list-menu-item add-list">
                <input
                    type="text"
                    placeholder="New List Name"
                    value={newListName}
                    onChange={(e) => setNewListName(e.target.value)}
                />
                <button type="submit"><FontAwesomeIcon icon={faPlus}/></button>
            </form>
            <div className="list-menu-container">
                {lists.map((list) => (
                    <div key={list.id} className={`list-menu-item ${selectedListId === list.id ? 'selected' : ''}`}>
                        <span className="list-name" onClick={() => handleSelectList(list.id)}>{list.name}</span>
                        <span>{list?.todos?.length}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};
