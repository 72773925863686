import React, {useEffect} from "react";
import { TaskList } from "../task-list/TaskList";
import { AddTask } from "../add-task/AddTask";
import { TaskListHeader } from "../task-list-header/TaskListHeader";
import "./Dashboard.css";

export const Dashboard = ({ lists, selectedListId, setLists, onDeleteList }) => {
    const selectedList = lists.find((list) => list.id === selectedListId) || {};

    // Save lists to localStorage whenever the lists state changes
    useEffect(() => {
        localStorage.setItem("lists", JSON.stringify(lists));
    }, [lists]);

    // Save selectedListId to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem("selectedListId", JSON.stringify(selectedListId));
    }, [selectedListId]);

    const updateTodosForSelectedList = (updatedTodos) => {
        setLists(
            lists.map((list) =>
                list.id === selectedListId
                    ? { ...list, todos: updatedTodos }
                    : list,
            ),
        );
    };

    const updateListName = (newName) => {
        setLists(
            lists.map((list) =>
                list.id === selectedListId ? { ...list, name: newName } : list,
            ),
        );
    };

    const handleAddTodo = (task) => {
        const updatedTodos = [...selectedList.todos, task];
        updateTodosForSelectedList(updatedTodos);
    };

    const handleDeleteTodo = (id) => {
        const updatedTodos = selectedList.todos.filter(
            (todo) => todo.id !== id,
        );
        updateTodosForSelectedList(updatedTodos);
    };

    const handleEditTodo = (id) => {
        const updatedTodos = selectedList.todos.map((todo) =>
            todo.id === id ? { ...todo, edit: !todo.edit } : todo,
        );
        updateTodosForSelectedList(updatedTodos);
    };

    const handleUpdateTodo = (updatedTodo) => {
        const updatedTodos = selectedList.todos.map((todo) =>
            todo.id === updatedTodo.id ? updatedTodo : todo,
        );
        updateTodosForSelectedList(updatedTodos);
    };

    const handleSaveTodo = (task, id) => {
        const updatedTodos = selectedList.todos.map((todo) =>
            todo.id === id ? { ...todo, task, edit: false } : todo,
        );
        updateTodosForSelectedList(updatedTodos);
    };

    const shareCollection = () => {
        const dataToShare = {...selectedList, shared: true};
        const jsonString = JSON.stringify(dataToShare);
        const utf8Bytes = new TextEncoder().encode(jsonString);
        const encodedData = btoa(String.fromCharCode(...utf8Bytes));
        const shareLink = `${window.location.origin}/share?data=${encodedData}`;

        if (navigator.share) {
            navigator
                .share({
                    title: "EasyDone Collection",
                    text: "Check out this collection on EasyDone!",
                    url: shareLink,
                })
                .catch((error) => console.error("Sharing failed:", error));
        } else {
            // Fallback: Copy the link to clipboard
            navigator.clipboard.writeText(shareLink).then(() => {
                alert("Shareable link copied to clipboard!");
            });
        }
    };

    return (
        <div className="home-screen">
            {selectedListId ? (
                <div className="task-container">
                    <TaskListHeader
                        listName={selectedList.name}
                        onDeleteList={() => onDeleteList(selectedListId)}
                        updateListName={updateListName}
                        shareCollection={shareCollection}
                    />
                    <div className="todo-list-container">
                        <AddTask addTask={handleAddTodo} />
                        <TaskList
                            todos={selectedList.todos || []}
                            setTodos={updateTodosForSelectedList}
                            deleteTodo={handleDeleteTodo}
                            editTodo={handleEditTodo}
                            updateTodo={handleUpdateTodo}
                            saveTodo={handleSaveTodo}
                        />
                    </div>
                </div>
            ) : (
                <div>Select or create a list to get started!</div>
            )}
        </div>
    );
};
