import React, { useState } from "react";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faGripLines,
    faPencil,
    faPlus,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";

import "./Task.css";
import { Subtask } from "./subtask/Subtask";
import { Button } from "../../button/Button"; // Import a specific icon
import { generateId } from "../../../utils/Utils";
import ActionMenu from "../../action-menu/ActionMenu";
import {faEllipsis} from "@fortawesome/free-solid-svg-icons/faEllipsis";

export const Task = ({
    todo,
    toggleComplete,
    deleteTodo,
    editTodo,
    updateTodo,
    subtasks,
}) => {
    const [showInput, setShowInput] = useState(false);
    const [subtaskText, setSubtaskText] = useState("");
    const inputRef = useRef(null);
    const actions = [
        {
            label: "Add Subtask",
            icon: faPlus,
            callback: () => handleShowSubtaskInput(),
        },
        {
            label: "Edit Task",
            icon: faPencil,
            callback: () => editTodo(todo.id),
        },
        {
            label: "Delete Task",
            icon: faTrash,
            callback: () => deleteTodo(todo.id),
        },
    ];

    const handleFocus = () => {
        setTimeout(() => {
            inputRef.current.focus();
        }, 0);
    };

    const handleShowSubtaskInput = () => {
        setShowInput(true);
        handleFocus();
    };

    const handleUpdateSubtasks = (updatedSubtasks) => {
        const updatedTodo = { ...todo, subtasks: updatedSubtasks };
        updateTodo(updatedTodo);
    };

    const handleSaveSubtask = (subtask) => {
        const updatedSubtasks = subtasks.map((s) =>
            s.id === subtask.id ? subtask : s,
        );
        handleUpdateSubtasks(updatedSubtasks);
    };

    const handleDeleteSubtask = (todo, subtaskId) => {
        const updatedSubtasks = subtasks.filter(
            (subtask) => subtask.id !== subtaskId,
        );
        handleUpdateSubtasks(updatedSubtasks);
    };

    const handleCompleteSubtask = (subtask) => {
        const updatedSubtasks = subtasks.map((s) =>
            s.id === subtask.id ? subtask : s,
        );
        handleUpdateSubtasks(updatedSubtasks);
    };

    const handleAddSubtask = () => {
        const newSubtask = {
            id: generateId(),
            task: subtaskText,
            completed: false,
            edit: false,
        };

        const updatedSubtasks = [...subtasks, newSubtask];
        const updatedTodo = { ...todo, subtasks: updatedSubtasks };
        updateTodo(updatedTodo);
        setSubtaskText("");
    };

    const handleSubmitSubtask = (e) => {
        e.preventDefault();
        if (!subtaskText) return;
        handleAddSubtask();
        setShowInput(false);
    };

    const handleAddNewSubtask = () => {
        if (!subtaskText) return;
        handleAddSubtask();
        handleShowSubtaskInput();
    };

    return (
        <li className={`todo-wrapper line-separator`}>
            <div className={"todo-container"}>
                <span className="drag-handle">
                    <FontAwesomeIcon icon={faGripLines} />
                </span>
                {/* This is the handle */}
                <div
                    className={`${todo.completed && "completed"} todo-text`}
                    onClick={() => toggleComplete(todo.id)}
                >
                    {todo.task}
                </div>
                 <ActionMenu actions={actions} icon={faEllipsis} />
            </div>

            {showInput && (
                <form className={"new-subtask"} onSubmit={handleSubmitSubtask}>
                    <input
                        ref={inputRef}
                        type="text"
                        value={subtaskText}
                        onChange={(e) => setSubtaskText(e.target.value)}
                        placeholder="Add subtask"
                    />
                    <Button
                        type="submit"
                        status={"primary"}
                        icon={faPlus}
                    ></Button>
                    <Button
                        onClick={handleAddNewSubtask}
                        status={"primary"}
                        text={"Next"}
                    ></Button>
                </form>
            )}

            <ul className="subtasks">
                {subtasks.map((subtask) => (
                    <Subtask
                        key={subtask.id}
                        subtask={subtask}
                        toggleComplete={handleCompleteSubtask}
                        saveSubtask={handleSaveSubtask}
                        deleteSubtask={() =>
                            handleDeleteSubtask(todo, subtask.id)
                        }
                    />
                ))}
            </ul>
        </li>
    );
};
