import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons/faEllipsis';

// todo: refactor this component
const ActionMenu = ({ actions, icon }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuPosition, setMenuPosition] = useState({});

    const menuHeight = 120; // Fixed height of the menu
    const menuWidth = 165;

    const handleClick = (event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // Calculate available space
        const spaceBelow = windowHeight - rect.bottom;

        // Determine whether to place the menu above or below
        if (spaceBelow >= menuHeight) {
            // Enough space below
            setMenuPosition({
                top: event.pageY + 8,
                left: rect.left - menuWidth, // Align to the left of the trigger
                position: 'absolute',
            });
        } else {
            // Enough space above
            setMenuPosition({
                top: event.pageY - menuHeight - 8, // Place below the element
                left: rect.left - menuWidth, // Align to the left of the trigger
                position: 'absolute',
            });
        }

        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setMenuPosition({});
    };

    return (
        <div>
            {/* Trigger Button */}
            <button
                onClick={handleClick}
                style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                aria-haspopup="true"
                aria-expanded={Boolean(anchorEl)}
            >
                <FontAwesomeIcon icon={icon || faEllipsis} />
            </button>

            {/* Modal Overlay */}
            {anchorEl && (
                <>
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            backgroundColor: 'rgba(0, 0, 0, 0)', // Fully transparent overlay
                            zIndex: 1000,
                        }}
                        onClick={handleClose}
                    ></div>

                    {/* Dropdown Menu */}
                    <div
                        style={{
                            ...menuPosition,
                            backgroundColor: 'white',
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                            borderRadius: '4px',
                            padding: '8px',
                            zIndex: 1100, // Ensure menu is above the overlay
                        }}
                        role="menu"
                    >
                        {actions.map((action, index) => (
                            <div
                                key={index}
                                onClick={() => {
                                    action.callback();
                                    handleClose();
                                }}
                                style={{
                                    padding: '8px 12px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                role="menuitem"
                                tabIndex={0}
                            >
                                {action.icon && (
                                    <FontAwesomeIcon icon={action.icon} style={{ marginRight: 8 }} />
                                )}
                                {action.label}
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default ActionMenu;
