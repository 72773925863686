import React, {useEffect, useState, useRef} from "react";
import {useSearchParams, useNavigate} from "react-router-dom";

const SharedCollectionView = ({setSharedCollection}) => {
    const [error, setError] = useState("");
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const isProcessed = useRef(false); // Tracks if the collection has already been handled

    useEffect(() => {
        if (isProcessed.current) return; // Prevent re-processing

        const encodedData = searchParams.get("data");

        if (encodedData) {
            try {
                // Decode Base64 into a UTF-8 string
                const utf8Bytes = Uint8Array.from(
                    atob(encodedData),
                    (char) => char.charCodeAt(0)
                );
                const jsonString = new TextDecoder().decode(utf8Bytes);
                const decodedData = JSON.parse(jsonString);

                // Validate the collection
                if (decodedData.shared) {
                    setSharedCollection(decodedData); // Add the shared collection
                    isProcessed.current = true; // Mark as processed
                    navigate("/"); // Redirect to the home page
                } else {
                    throw new Error("Invalid collection: Missing 'shared' property.");
                }
            } catch (err) {
                setError("Failed to load shared collection. The link might be corrupted.");
                console.error(err);
            }
        } else {
            setError("No collection data found in the link.");
        }
    }, [searchParams, setSharedCollection, navigate]);

    if (error) {
        return <div className="error">{error}</div>;
    }

    return <div>Adding the shared collection...</div>;
};

export default SharedCollectionView;
