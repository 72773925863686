import React, {useState} from "react";
import {faPencil, faTrash} from "@fortawesome/free-solid-svg-icons";
import {faShare} from "@fortawesome/free-solid-svg-icons/faShare";

import "./TaskListHeader.css";
import {Button} from "../button/Button";
import ActionMenu from "../action-menu/ActionMenu";

export const TaskListHeader = ({listName, updateListName, shareCollection, onDeleteList}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [header, setHeader] = useState('');
    const actions = [
        {
            label: "Share Task",
            icon: faShare,
            callback: () => shareCollection(),
        },
        {
            label: "Edit List Name",
            icon: faPencil,
            callback: () => handleEditClick(),
        },
        {
            label: "Delete List",
            icon: faTrash,
            callback: () => onDeleteList(),
        },
    ];

    const handleSave = () => {
        if (header.trim()) {
            updateListName(header);
            setIsEditing(false);
            setHeader('');
        }
    };

    const handleEditClick = () => {
        setHeader(listName);
        setIsEditing(true);
    };

    return (
        <div>
            {isEditing
                ? <form
                    onSubmit={handleSave}
                    className={'todo-header-container'}
                >
                    <input
                        placeholder="Enter list name"
                        type="text"
                        value={header}
                        onChange={(e) => setHeader(e.target.value)}
                    />
                    <Button onClick={handleSave} text="Save" type="primary"/>
                </form>
                : <div className={'todo-header-container header-line'}>
                    <h2 className={'todo-header-text'}>{listName}</h2>
                    <div className={'action-buttons'}>
                        <ActionMenu actions={actions}/>
                    </div>
                </div>
            }
        </div>
    );
};
